<script setup>
  import { ref } from 'vue';
  import { useBreakpoints } from '$storefront';

  const props = defineProps({
    product: Object,
    sections: Array,
    recommended: Array,
    reviews: { type: Object, required: true },
  });

  const breakpoints = useBreakpoints();

  const parameters = ref({
    navigation: true,
    spaceBetween: 24,
    breakpoints: {
      1: {
        slidesPerView: 1.25,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
    },
  });
</script>
<template>
  <div v-if="sections.length > 0 || product.list_attributes.length > 0 || product.reviews_average > 0" class="container product-description">
    <ProductDescriptionNav v-if="breakpoints.lg" :aria-hidden="!breakpoints.lg" :class="{'product-description__nav--hidden': !breakpoints.lg}" :product :recommended :sections class="product-description__nav" />
    <div class="wrapper product-description__content">
      <ProductSection v-if="product.list_attributes.length > 0" id="specification" :title="$t('Specyfikacja')" class="product-description--margin-scroll">
        <ProductSpecification :product="product" />
      </ProductSection>
      <ProductSection v-if="sections.length > 0" id="description" :title="$t('Opis')" class="product-description--margin-scroll">
        <SectionRenderer :sections="sections" />
      </ProductSection>
      <ProductSection v-if="recommended.length > 0" id="recommended" :title="$t('Polecane')" class="product-description--margin-scroll">
        <div class="product-description__products">
          <SfProductsCarousel :parameters :products="recommended" />
        </div>
      </ProductSection>
      <ProductSection v-if="product.reviews_average > 0" id="reviews" :title="$t('Opinie')" class="product-description--margin-scroll">
        <div class="product-description__reviews">
          <div class="product-description__stars">
            <span class="product-description__general-rating">
              {{ $t('Ogólna ocena') + ': ' }}
            </span>
            <span class="product-description__general-rating-value">{{ product.reviews_average }}</span>
            <SfStars :count="product.reviews_count" :rating="product.reviews_average" />
          </div>
          <ProductReviews :product="product" :reviews="reviews" />
        </div>
      </ProductSection>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-description {
    display: flex;
    flex-direction: column;

    &__nav--hidden {
      display: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem;
      padding: 2.5rem 0;
      background-color: var(--color-white);
    }

    &__general-rating {
      font-weight: var(--font-weight-500);
    }

    &__stars {
      --sf-stars-padding-y: 0;
      --sf-stars-padding-x: 0;

      display: flex;
      align-items: center;
      column-gap: 0.75rem;
    }

    &--margin-scroll {
      scroll-margin-top: 160px;
    }

    &__general-rating-value {
      font-weight: var(--font-weight-700);
    }

    &__nav {
      font-size: 1rem;
      padding: 1rem 2.5rem;
    }

    #description {
      --sf-section-renderer-gap: 1.5rem;

      :deep(.download) {
        .download__header {
          font-size: 1.125rem;

          &::after {
            display: none;
          }
        }

        .download__file {
          background-color: var(--color-white);

          &:nth-child(4n+1),
          &:nth-child(4n+2) {
            background-color: var(--color-primary-body-background);
          }
        }

        @media(max-width: 992px) {
          padding: 0;

          .download__file {
            &:nth-child(4n+1),
            &:nth-child(4n+2) {
              background-color: var(--color-white);
            }

            &:nth-child(odd) {
              background-color: var(--color-primary-body-background);
            }
          }
        }
      }
    }
  }

  @media(max-width: 992px) {
    .product-description {
      gap: 1.5rem;

      &__content {
        padding: 1rem 0.625rem;
        gap: 1.5rem;
      }

      #recommended {
        padding: 0 0.625rem;
      }
    }
  }
</style>
