<script setup>
  import { ref } from 'vue';
  import { useShared } from '@plenny/visitor';
  import RealizationTime from './RealizationTime.vue';

  const props = defineProps({
    product: { type: Object, required: true },
    brand: { type: Object, required: true },
  });

  const variant = defineModel('variant', { type: Object, required: false });
  const related = ref([]);
  const shared = useShared();
  const successModalVisible = ref(false);

  const onVariantAdded = (res) => {
    successModalVisible.value = true;
    related.value = res.related;
  };
</script>
<template>
  <div class="product-actions">
    <div class="product-actions__info">
      <div class="product-actions__price">
        <SfProductPrice :price="variant || product" presentation />
        <div class="product-actions__omnibus">
          <SfProductOmnibus :price="variant || product" />
        </div>
      </div>
      <div>
        <SfProductStock :variant="variant" />
        <RealizationTime :realization_from="variant.realization_from"
          :realization_to="variant.realization_to" />
      </div>
    </div>
    <div class="product-actions__picker">
      <div class="product-actions__variant-picker-wrapper">
        <span class="product-actions__variant-picker-title">{{ $t('Wybór wariantu:') }}</span>
        <SfVariantPicker v-model:variant="variant" :product="product" class="product-actions__variant-picker" />
      </div>
      <SfCartStoreButton :variant="variant" @added="onVariantAdded" />
      <CartButtonSuccessModal :open="successModalVisible" :product="product" :related="related" :variant="variant" @update:open="successModalVisible = $event" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .product-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;

    &__info {
      display: flex;
      gap: 2rem;
    }

    &__price {
      --sf-product-price-presentation-current-font-line-height: 1;
    }

    a.product-actions__data-value {
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }
    }

    &__picker {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 50%;

      :deep(.cart-store-button__button) {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }

    &__variant-picker-title {
      font-weight: var(--font-weight-600);
    }

    &__variant-picker {
      margin-top: 0.5rem;
    }
  }
</style>
