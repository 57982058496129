<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    page: Object,
  });
  const twitterLink = ref(`https://twitter.com/intent/tweet?url=${props.page.meta_url}`);
  const facebookLink = ref(`https://www.facebook.com/share.php?u=${props.page.meta_url}`);
  const linkedinLink = ref(`https://www.linkedin.com/sharing/share-offsite/?url=${props.page.meta_url}`);
</script>

<template>
  <div class="share">
    <span>{{ $t('Udostępnij to na') + ':' }}</span>
    <div class="icons-wrapper">
      <VisitorLink :href="facebookLink" target="_blank" class="facebook-share">
        <SfIconFacebook />
      </VisitorLink>
      <VisitorLink :href="twitterLink" target="_blank" class="twitter-share">
        <SfIconTwitter />
      </VisitorLink>
      <VisitorLink :href="linkedinLink" target="_blank" class="linkedin-share">
        <SfIconLinkedIn />
      </VisitorLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .share {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;

    .icons-wrapper {
      display: flex;
      font-size: 16px;

      .facebook-share,
      .twitter-share,
      .linkedin-share {
        display: flex;
        align-items: center;
        padding: 8px;
        transition: var(--transition-primary);

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
</style>
