<script setup lang="ts">
  import { computed, type PropType } from 'vue';
  import { trans } from '@plenny/translator';

  type Contact = {
    type: 'phone' | 'internal' | 'mobile' | 'fax' | 'email',
    value: string,
  }

  type Item = {
    header: string,
    icon: string | null,
    contacts: Contact[],
  }

  const props = defineProps({
    items: { type: Array as PropType<Item[]>, required: true },
  });

  const types = computed(() => {
    return {
      phone: trans('tel.'),
      internal: trans('tel. wew.'),
      mobile: trans('tel. kom.'),
      fax: trans('fax'),
      email: trans('e-mail'),
    };
  });

  const items = computed(() => {
    return props.items.filter((item) => !!item.header);
  });

  function makeContactHref(contact: Contact) {
    switch (contact.type) {
      case 'phone':
      case 'mobile':
      case 'fax':
        return `tel:${contact.value}`;
      case 'email':
        return `mailto:${contact.value}`;
      default:
        return 'javascript:void(0)';
    }
  }
</script>
<template>
  <div class="cards">
    <div class="cards__container">
      <Segment v-for="item in items" class="section" :title="item.header">
        <div class="card">
          <div v-if="item.contacts" class="card__contacts">
            <div v-for="contact in item.contacts" class="contact">
              <a :href="makeContactHref(contact)">
                {{ types[contact.type] + ':' }} {{ contact.value }}
              </a>
            </div>
          </div>
          <div v-if="item.icon" class="card__icon" aria-hidden="true" v-html="item.icon" />
        </div>
      </Segment>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .cards {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: stretch;
      gap: 1.5rem;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
    gap: 1.5rem;
    padding: 1.5rem;
    background: var(--color-white);
    border-radius: 5px;

    &__contacts {
      display: flex;
      flex-direction: column;
    }

    &__icon {
      display: flex;
      flex: 0 0 2rem;

      :deep(svg) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
</style>
