<script lang="ts" setup>
  import { ref } from 'vue';
  import { $get, route } from '@plenny/visitor';

  const props = defineProps({
    reviews: { type: Object, required: true },
    product: Object,
  });

  const reviewsToShow = ref(props.reviews);

  const handleRedirect = (page: number) => {
    $get(route('api.v1.web.product.review.index', { product: props.product, page }))
      .then((res) => {
        reviewsToShow.value = res.reviews;
      });
  };

</script>
<template>
  <div class="products-review">
    <div v-for="review in reviewsToShow.data" class="products-review__item">
      <div class="products-review__header">
        <p class="products-review__name">{{ review.name }}</p>
        <SfDateTime :value="review.created_at" class="products-review__time" />
        <SfStars :rating="review.rating" />
        <!--        <span>{{ review.rating }}</span>-->
      </div>
      <p class="products-review__review" v-if="review.comment ">
        {{ review.comment }}
      </p>
    </div>
    <SfPagination :currentPage="reviewsToShow.current_page" :lastPage="reviewsToShow.last_page" :links="reviewsToShow.links" @redirect="handleRedirect" />
  </div>
</template>

<style lang="scss" scoped>
  .products-review {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    &__item {
      padding: 0.625rem;
      background-color: var(--color-primary-body-background);
    }

    &__header {
      --sf-stars-padding-y: 0;
      --sf-stars-padding-x: 0;
      --sf-stars-star-size: 0.875rem;

      display: flex;
      column-gap: 1rem;
      line-height: 1;

      :deep(.stars) {
        align-items: flex-start;
      }
    }

    &__name {
      font-weight: var(--font-weight-500);
    }

    &__review {
      margin-top: 0.625rem;
    }
  }

  //@media (max-width: 768px) {
  //  .products-review {
  //    margin-top: 26px;
  //
  //    .review-wrapper {
  //      .header {
  //        column-gap: 1rem;
  //        margin-bottom: 2px;
  //
  //        .name {
  //          font-size: 18px;
  //        }
  //      }
  //
  //      .review {
  //        font-size: 14px;
  //      }
  //    }
  //  }
  //}
</style>
