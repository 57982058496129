<script lang="ts" setup>
  import { computed } from 'vue';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    link: { type: String, required: true, default: '#' },
    photos: { type: Array, required: true, default: [] },
  });

  const photo = computed(() => {
    return 'url("/photos/1720x800/' + props.photos[0]?.filename + '")';
  });
</script>
<template>
  <section :class="{ 'newsletter-banner--exist': !!props.photos[0] }" class="newsletter-banner">
    <VisitorLink v-if="props.photos[0]" class="newsletter-banner__picture-wrapper" :href="link">
      <span />
    </VisitorLink>
    <Newsletter class="newsletter-banner__newsletter" />
  </section>
</template>
<style scoped lang="scss">
  .newsletter-banner {
    --newsletter-banner-photo: v-bind(photo);

    display: grid;
    grid-template-columns: 1fr;

    &__picture-wrapper {
      background-image: var(--newsletter-banner-photo);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &--exist {
      grid-template-columns: 3fr 2fr;
    }
  }

  @media(max-width: 1400px) {
    .newsletter-banner--exist {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media(max-width: 992px) {
    .newsletter-banner {
      grid-template-columns: 1fr;
      gap: 1.5rem;
      padding: 0 0.625rem;

      &__picture-wrapper {
        height: 50vw;
        max-height: 300px;
      }
    }
  }
</style>
