<script setup lang="ts">
  import { onMounted, onBeforeUnmount, ref } from 'vue';

  defineOptions({
    layout: false,
  });

  const props = defineProps({
    code: Number,
    message: String,
  });

  let interval: number;

  const time = ref(60);

  onMounted(() => {
    if (props.code === 503) {
      interval = setInterval(() => {
        time.value = time.value - 1;

        if (time.value <= 0) {
          clearInterval(interval);
          window.location.reload();
        }
      }, 1000);
    }
  });

  onBeforeUnmount(() => {
    clearInterval(interval);
  });
</script>
<template>
  <main class="container">
    <article class="wrapper error-wrapper">
      <header>
        <SegmentHeading larger type="h1">
          {{ code }}
        </SegmentHeading>
      </header>
      <section>
        <p class="error-message">{{ message }}</p>
      </section>
      <section v-if="code === 503">
        <p class="timer">
          {{ $tc('Ponowna próba za: :time sekundę|Ponowna próba za: :time sekundy|Ponowna próba za: :time sekund', time, { time }) }}
        </p>
      </section>
      <footer>
        <SfButton large :to="route('web.homepage.index')">
          {{ $t('Strona główna') }}
        </SfButton>
      </footer>
    </article>
  </main>
</template>
<style scoped lang="scss">
  .error-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 80px 0;
    font-size: 2rem;

    .error-message {
      text-align: center;
    }

    .timer {
      font-size: 1rem;
    }
  }
</style>
