import { trans } from '@plenny/translator';

type DayOpening = { from: string, to: string } | { closed: boolean } | null;

type Days = {
  mon: DayOpening,
  tue: DayOpening,
  wed: DayOpening,
  thu: DayOpening,
  fri: DayOpening,
  sat: DayOpening,
  sun: DayOpening,
}

type HoursEntry = { days: string, hours: string };

export function useOpeningHours() {
  const names = {
    mon: trans('poniedziałek'),
    tue: trans('wtorek'),
    wed: trans('środa'),
    thu: trans('czwartek'),
    fri: trans('piątek'),
    sat: trans('sobota'),
    sun: trans('niedziela'),
  };

  function makeHours({ from, to }) {
    if (from && to) {
      return from + ' - ' + to;
    } else {
      return trans('zamknięte');
    }
  }

  function entryFactory({ from, to, hours }) {
    let days;

    if (from !== to) {
      days = names[from] + ' - ' + names[to];
    } else {
      days = names[to];
    }

    return { days, hours };
  }

  return function (days: Days, grouping: boolean = true): HoursEntry[] {
    let groups = [];
    let group;

    for (let day of ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']) {
      let entry = days[day] as DayOpening;

      if (!entry) {
        group = undefined;
        continue;
      }

      let hours = makeHours(entry);

      if (!group) {
        group = { from: day, to: day, hours };
        groups.push(group);
      }

      if (grouping && group.hours === hours) {
        group.to = day;
        continue;
      }

      group = { from: day, to: day, hours };
      groups.push(group);
    }

    return groups.map((group) => entryFactory(group));
  };
}
