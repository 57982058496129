<script setup lang="ts">
  import { ref, onMounted, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: Array,
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      slidesPerView: 1.75,
      spaceBetween: 10,
      breakpoints: {
        576: {
          slidesPerView: 2.5,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 3.5,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 4.5,
          spaceBetween: 24,
        },
        1400: {
          slidesPerView: 5,
          spaceBetween: 24,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <section class="items-carousel contact-segments ">
    <div ref="htmlElement" class="swiper items-carousel__container contact-segments__container">
      <div class="swiper-wrapper">
        <template v-for="item in items">
          <div class="swiper-slide items-carousel__slide contact-segments__article" v-if="item.photos.length > 0">
            <VisitorLink :href="item.link" class="contact-segments__item">
              <MediaPicture :photo="item.photos" type="icon" />
              <span class="contact-segments__name">
                {{ item.name }}
              </span>
            </VisitorLink>
          </div>
        </template>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </section>
</template>
<style scoped lang="scss">
  .contact-segments {

    &__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1.5rem;
      text-align: left;
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
      padding: 1.5rem;
      background-color: var(--color-primary-white);
      width: 100%;

      &__name {
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
      }

      &:hover .contact-segments__name {
        color: var(--color-primary-blue);
      }

      :deep(img) {
        height: 4rem;
      }
    }
  }

  @media(max-width: 992px) {
    .contact-segments {
      padding: 0.625rem;
    }
  }

  @media(max-width: 772px) {
    .contact-segments {

      &__item {
        gap: 1rem;
        font-size: 1rem;
        padding: 1.5rem 1rem;
      }

      :deep(img) {
        height: 3rem;
        width: 3rem;
      }
    }
  }

  @media(max-width: 576px) {
    .contact-segments {
      padding-right: 0;
    }
  }
</style>
