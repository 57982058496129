<script setup>
  const props = defineProps({
    title: {
      type: String,
      required: false,
    },
  });
</script>
<template>
  <div class="frame-wrapper">
    <div v-if="title" class="title">
      {{ props.title }}
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .frame-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    row-gap: 0.625rem;

    height: fit-content;

    .title {
      background-color: var(--color-white);
      padding: 0.75rem;
      font-size: 1.25rem;
      font-weight: var(--font-weight-500);
    }

    .content {
      padding: 0 0.75rem;
    }
  }
</style>
