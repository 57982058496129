<script setup>
  import { ref, onMounted, watch } from 'vue';
  import { $get, route } from '@plenny/visitor';

  const open = defineModel('open', { type: Boolean, required: false, default: false });

  const results = ref([]);
  const wrapper = ref();
  const query = ref();

  onMounted(() => {
    wrapper.value.querySelector('input').focus();
  });

  let timeout;

  watch(query, (query) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      $get(route('api.v1.web.product.search', { query })).then((res) => {
        results.value = res;
      });
    }, 250);
  });

  function handler(link) {
    $get(link).then((res) => {
      results.value = res;
    });
  }
</script>
<template>
  <section class="search-body">
    <div class="input-wrapper" ref="wrapper">
      <SfFormInput type="text" name="search" autofocus :label="$t('Szukaj produktu')" v-model="query" />
    </div>
    <div class="results-wrapper">
      <div v-if="results.total > 0" class="total">
        <span>{{ $tc('Znaleźliśmy :total wyników:|Znaleźliśmy :total wyniki:', results.total, { total: results.total }) }}</span>
      </div>

      <ProductCard v-if="results.total > 0" v-for="product in results.data" :product="product" horizontal @click="open = false" />

      <div class="no-results" v-else-if="results.total <= 0 && !!query">
        <span>{{ $t('Niestety nic nie znaleźliśmy.') }}</span>
      </div>
      <div class="no-results" v-else>
        <span>{{ $t('Wprowadź frazę aby rozpocząć wyszukiwanie.') }}</span>
      </div>

      <SfButton primary v-if="results.total > 0" :href="route('web.product.index', { search: query })" @click="open = false">
        {{ $t('Zobacz wszystkie w sklepie') }}
      </SfButton>
    </div>
  </section>
</template>
<style scoped lang="scss">
  .search-body {
    flex-grow: 1;

    .results-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;

      .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px dashed var(--color-medium-gray);
        transition: border-color ease 250ms;
        border-radius: 16px;
        padding: 16px;
        gap: 16px;

        @media screen and (min-width: 576px) {
          flex-direction: row;
          align-items: flex-start;
        }

        &:hover {
          border-color: var(--color-primary);
        }

        :deep(img) {
          width: 150px;
        }

        .text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 8px;
          line-height: 1.5;

          .product-title {
            font-size: 18px;
          }

          .product-description {
            font-size: 14px;
            color: var(--color-dark-gray);
          }
        }
      }
    }

    .no-results {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      padding: 16px;
    }

    .total {
      font-size: 14px;
      font-weight: 700;
      padding: 16px;
    }
  }
</style>
