<script setup lang="ts">
  import { ref, onMounted, type Ref, onBeforeUpdate, onUpdated, onBeforeUnmount } from 'vue';
  import type { NavButtons } from '$types';
  import Swiper from 'swiper';
  import { Navigation } from 'swiper/modules';

  defineOptions({
    inheritAttrs: false,
  });

  defineProps({
    items: Array,
  });

  const htmlElement = ref() as Ref<HTMLElement>;
  const htmlNavigation = ref() as Ref<NavButtons>;
  const swiper = ref() as Ref<Swiper>;

  function initializeSwipers() {
    swiper.value = new Swiper(htmlElement.value, {
      modules: [Navigation],
      slidesPerView: 2.5,
      breakpoints: {
        768: {
          slidesPerView: 3.5,
        },
        992: {
          slidesPerView: 4.5,
        },
        1200: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 5,
        },
      },
      navigation: {
        nextEl: htmlNavigation.value.next.htmlElement,
        prevEl: htmlNavigation.value.prev.htmlElement,
      },
    });
  }

  function destroySwipers() {
    swiper.value?.destroy();
  }

  onMounted(() => {
    initializeSwipers();
  });

  onBeforeUpdate(() => {
    destroySwipers();
  });

  onUpdated(() => {
    initializeSwipers();
  });

  onBeforeUnmount(() => {
    destroySwipers();
  });
</script>
<template>
  <section class="items-carousel advantages">
    <div ref="htmlElement" class="swiper items-carousel__container advantages__container">
      <div class="swiper-wrapper">
        <template v-for="item in items">
          <div class="swiper-slide items-carousel__slide advantages__article">
            <div class="advantages__item">
              <MediaPicture :photo="item.photos" type="icon" class="advantages__icon" />
              <span>
                {{ item.name }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <SfCarouselButtons ref="htmlNavigation" />
  </section>
</template>
<style scoped lang="scss">
  .advantages {

    &__container {
      background-color: var(--color-primary-white);
      padding: 2.5rem 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      padding: 0 1rem;
      text-align: center;
      font-weight: var(--font-weight-500);

      &:not(:last-child) {
        border-right: 1px solid var(--color-primary-light-gray);
      }

      :deep(picture) {
        height: 3rem;
        width: 3rem;

        img {
          height: 100%;
          aspect-ratio: 1;
        }
      }
    }

    .advantages__article:not(:last-child) .advantages__item {
      border-right: 1px solid var(--color-primary-light-gray);
    }
  }

  @media(max-width: 992px) {
    .advantages {
      padding: 0 0.625rem;

      &__container {
        padding: 1.5rem 0;
      }

      &__content {
        padding: 0.5rem;
      }

      &__item {
        font-size: 0.875rem;

        :deep(picture) {
          height: 2rem;
          width: 2rem;
        }
      }

      .advantages__article:not(:last-child) .advantages__item {
        border-right: none;
      }
    }
  }

  @media(max-width: 768px) {
    .advantages {
      padding-right: 0;
    }
  }
</style>
