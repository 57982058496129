<script lang="ts" setup>
  import { computed, ref } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { trans } from '@plenny/translator';
  import { useQuery, $get, url, $patch, route, useShared } from '@plenny/visitor';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    price: { type: Object, required: true },
    attributes: { type: Object, required: true },
    brands: { type: Object, required: true },
    search: { type: String, required: false },
    options: { type: Object, required: true },
    categories: { type: Object, required: true },
    products: { type: Object, required: true },
  });

  const sorting = [
    { label: trans('Nazwa alfabetycznie'), value: 'name' },
    { label: trans('Cena: od najtańszych'), value: 'cheap' },
    { label: trans('Cena: od najdroższych'), value: 'expensive' },
    { label: trans('Ocena: od najlepszej'), value: 'rating' },
  ];

  const breakpoints = useBreakpoints();
  const query = useQuery();
  const shared = useShared();

  const sort = ref(query.value.sort);
  const areFiltersOpen = ref(false);
  const processing = ref(false);

  const hasProducts = computed(() => props.products.data.length > 0);
  const type = computed(() => shared.value.product_list_mode);

  function actionChangeSort() {
    processing.value = true;

    $get(url(window.location.pathname, { ...query.value, sort: sort.value })).then(() => {
      processing.value = false;
    });
  }

  function actionGridMode() {
    processing.value = true;

    $patch(route('api.v1.web.product.list.mode'), { mode: 0 }).then(() => {
      processing.value = false;
    });
  }

  function actionListMode() {
    processing.value = true;

    $patch(route('api.v1.web.product.list.mode'), { mode: 1 }).then(() => {
      processing.value = false;
    });
  }
</script>
<template>
  <div class="shop-products">
    <div v-if="breakpoints.lg" class="shop-products__filters">
      <ShopFilters v-bind="{ categories, brands, price, attributes, options, search }" />
    </div>
    <div class="shop-products__content">
      <section class="shop-products__panel">
        <div class="shop-products__tools">
          <div class="shop-products__type">
            <div class="shop-products__icons">
              <IconGridFill :class="{ 'shop-products__icon--active': type === 0, 'shop-products__icon--disabled': processing }" class="shop-products__icon" @click="actionGridMode" />
              <IconShopList :class="{ 'shop-products__icon--active': type === 1, 'shop-products__icon--disabled': processing }" class="shop-products__icon" @click="actionListMode" />
            </div>
            <span class="shop-products__count">
              {{ $tc(':count produkt|:count produkty|:count produktów', products.total, { count: products.total }) }}
            </span>
          </div>
          <template v-if="!breakpoints.lg">
            <SfButton small @click="areFiltersOpen = !areFiltersOpen">
              {{ $t('Filtry') }}
            </SfButton>
            <SfModal v-model:open="areFiltersOpen">
              <SfModalHeader v-model:open="areFiltersOpen">
                {{ $t('Filtry') }}
              </SfModalHeader>
              <ShopFilters v-bind="{ categories, brands, price, attributes, options, search }" />
            </SfModal>
          </template>
        </div>
        <div class="shop-products__sort">
          <SfFormSelect v-model="sort" :disabled="processing" :label="$t('Sortowanie')" :options="sorting" :placeholder="$t('Domyślne')" @change="actionChangeSort" />
        </div>
      </section>
      <section v-if="hasProducts" :class="{ 'shop-products__products--list': type === 1 }" class="shop-products__products">
        <ProductCard v-for="product in products.data" :key="product.id" :horizontal="type === 1" :product="product" class="shop-products__product" />
      </section>
      <section v-else class="shop-products__empty">
        <SfDataPlaceholder>{{ $t('Przepraszamy, nie znaleźliśmy produktów spełniających podane kryteria.') }}</SfDataPlaceholder>
      </section>
      <SfPagination :currentPage="products.current_page" :lastPage="products.last_page" :links="products.links" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .shop-products {
    display: flex;
    align-items: flex-start;
    column-gap: 2.5rem;
    width: 100%;

    &__filters {
      width: 320px;
      flex: 0 0 320px;
    }

    &__content {
      width: calc(100% - 320px);
    }

    &__panel {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      --sf-input-background: transparent;
    }

    &__sort-select {
      width: auto;
    }

    &__type {
      display: flex;
      column-gap: 2rem;
      align-items: center;
    }

    &__count {
      font-size: 0.875rem;
    }

    &__icons {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }

    &__icon {
      font-size: 1.5rem;
      color: var(--color-grey);
      cursor: pointer;

      &--active {
        color: var(--color-black);
      }

      &--disabled {
        pointer-events: none;
        opacity: 0.75;
      }
    }

    &__products {
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 1fr));
      gap: 1.5rem;
      margin-top: 1.25rem;

      &.shop-products__products--list {
        grid-template-columns: 1fr;
      }
    }

    &__empty {
      margin-top: 1.5rem;
      padding: 1.5rem;
      background-color: var(--color-white);
    }
  }

  @media (max-width: 1400px) {
    .shop-products {
      gap: 1.5rem;

      &__products {
        grid-template-columns: repeat(3, minmax(180px, 1fr));
      }
    }
  }

  @media (max-width: 1200px) {
    .shop-products__products {
      padding: 0;
      grid-template-columns: repeat(2, minmax(180px, 1fr));
    }
  }

  @media (max-width: 992px) {

    .shop-products {
      flex-direction: column;

      &__filters,
      &__content {
        width: 100%;
      }

      &__filters {
        padding: 0.75rem;
        display: flex;
        justify-content: center;
        flex: 0 1 auto
      }

      &__products {
        gap: 0.625rem;
        grid-template-columns: 1fr 1fr;
        margin-top: 1rem;
        padding: 0 0.625rem;

        &.shop-products__products--list {
          grid-template-columns: 1fr 1fr;
        }
      }

      &__panel {
        flex-direction: column;
        gap: 0.75rem;
        width: 100%;
      }

      &__tools {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        background-color: var(--color-white);
        padding: 0.625rem;
      }

      &__type {
        flex-direction: row-reverse;
      }

      &__empty {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 576px) {
    .shop-products__products {
      grid-template-columns: 1fr;

      &.shop-products__products--list {
        grid-template-columns: 1fr;
      }
    }
  }
</style>
