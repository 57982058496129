<script lang="ts" setup>
  import { route } from '@plenny/visitor';

  defineProps({
    recommended: Array,
  });


  function target(product) {
    return route('web.product.show', { slug: product.slug });
  }

</script>

<template>
  <div class="more-variants">
    <div class="more-variants__header">
      <IconFlame />
      {{ $t('Inne warianty') }}
    </div>
    <div class="more-variants__grid">
      <div v-for="index in 13" class="more-variants__item">
        <VisitorLink :href="target(recommended[0])">
          <MediaPicture :photo="recommended[0].photo" />
        </VisitorLink>
      </div>

      <SfButton :href="route('web.product.index')">{{ $t('WIĘCEJ') }}</SfButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .more-variants {
    border-top: 1px solid var(--color-grey);
    margin-top: 1rem;
    padding-top: 0.5rem;

    &__header {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 0.5rem;
      margin-top: 0.5rem;
    }
  }
</style>