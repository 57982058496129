<script lang="ts" setup>
  import { ref, watch } from 'vue';
  import { useShared, route } from '@plenny/visitor';
  import { useBreakpoints, useScrollDetector } from '$storefront';
  import HeaderPromotions from './HeaderPromotions.vue';

  const props = defineProps({
    homepage: Object,
  });

  const headerBottom = ref();

  const scroll = ref(false);
  const show = ref(false);
  const showMenu = ref(false);
  const openSearch = ref(false);
  const searchMobilebar = ref(false);

  const breakpoints = useBreakpoints();
  const shared = useShared();

  useScrollDetector({
    threshold: 8,
    callback: ({ up }) => {
      if (headerBottom.value) {
        if (up) {
          headerBottom.value.classList.add('header__bottom--open');
          scroll.value = false;
        } else {
          headerBottom.value.classList.remove('header__bottom--open');
          scroll.value = true;
        }
      }
    },
  });

  watch(scroll, () => {
    if (!scroll.value) {
      show.value = false;
      searchMobilebar.value = false;
    }
  });
</script>
<template>
  <header :class="{ 'header--scroll': scroll, 'search-mobile-active': searchMobilebar }" class="header">
    <HeaderPromotions v-if="breakpoints.md" id="header-top-bar-info" :aria-hidden="!breakpoints.md" />
    <div class="header__top">
      <div class="container header__top__container">
        <div class="header__address-wrapper">
          <div v-if="shared.department.phone" class="header__address">
            <a :href="`callto:${shared.department.phone}`">
              <SfIconTelephone />
              {{ shared.department.phone }}
              - {{ $t('SPRZEDAŻ') }}
            </a>
          </div>
          <div v-if="shared.department.phone" class="header__address">
            <a :href="`callto:${shared.department.phone}`">
              <SfIconTelephone />
              {{ shared.department.phone }}
              - {{ $t('SPRZEDAŻ') }}
            </a>
          </div>
          <div v-if="shared.department.email" class="header__address">
            <a :href="`mailto:${shared.department.email}`">
              <SfIconSend />
              {{ shared.department.email }}
            </a>
          </div>
        </div>
        <div class="header__address-social">
          <a href="https://www.facebook.com/bispolcandles/?locale=pl_PL" target="_blank">
            <SfIconFacebook />
          </a>
          <a href="https://www.instagram.com/bispol_candles/" target="_blank">
            <SfIconInstagram />
          </a>
        </div>
      </div>
    </div>
    <div v-if="breakpoints.lg" ref="headerBottom" class="header__bottom header__bottom--open">
      <div class="container header__bottom__inner">
        <div class="header__bottom__inner__wrapper">
          <div class="header__bottom__inner__wrapper__content">
            <VisitorLink :href="route('web.homepage.index')" class="header__top__logo" target="_self">
              <SfLogo class="header__top__logo__img" />
            </VisitorLink>
            <nav v-if="breakpoints.lg" class="navigation">
              <ul>
                <HeaderNavItem v-for="item in shared.menu.menu_top" :depth="0" :item="item" />
              </ul>
            </nav>
          </div>
          <div class="header__search">
            <HeaderSearch />
          </div>
          <div class="header__icons">
            <HeaderFavorite v-if="breakpoints.lg" :class="{ scroll }" />
            <HeaderAccount v-if="breakpoints.lg" :class="{ scroll }" />
            <MiniCart :class="{ scroll }" />
          </div>
        </div>
      </div>
    </div>
  </header>
  <MenuDrawer v-if="!breakpoints.lg" v-model:open="showMenu" />
</template>
<style lang="scss" scoped>
  @keyframes show-overflow {
    from {
      overflow: hidden;
    }
    to {
      overflow: visible;
    }
  }

  .icon-hamburger {
    font-size: 1.5rem;
  }

  .fade-down-enter-active,
  .fade-down-leave-active {
    transition: var(--transition-primary-fade);
  }

  .fade-down-enter-from,
  .fade-down-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }

  .search-mobile-active.header {
    box-shadow: none;
  }

  .mobile-search {
    position: fixed;
    top: 49px;
    width: 100%;
    z-index: 997;
    padding: 5px 15px 10px;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
  }

  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--color-white);
    box-shadow: var(--box-shadow-primary);
    transition: var(--transition-header-scroll);

    &__line {
      display: none;
    }

    &__top {
      background: var(--bispol-dark);
      color: var(--color-white);

      &__container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 98%;
        margin: 0 auto;
        height: 60px;
        padding: 15px 0;
      }

      &__logo__img {
        max-width: 200px;
        height: 70px;
      }
    }

    &__bottom {
      &__inner {
        width: 98%;
        margin: 0 auto;
      }
    }

    &__search {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 2.5rem;
    }

    &__icons {
      display: flex;
      align-items: center;
      gap: 2.5rem;
    }

    &__address-wrapper {
      display: flex;
      gap: 2rem;
      font-size: 0.75rem;
    }

    &__address-social {
      display: flex;
      gap: 2rem;

      a {
        &:hover {
          svg {
            color: var(--color-primary);
          }
        }
      }

      svg {
        color: var(--color-grey);
        transition-property: color;
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
      }
    }

    &__address {
      display: flex;
      align-items: center;

      a {
        display: flex;
        gap: 0.75rem;
        align-items: center;
        color: var(--color-white);
        text-wrap: nowrap;

        &:hover {
          color: var(--color-primary);
        }

        svg {
          color: var(--color-primary);
          font-size: 1rem
        }
      }
    }
  }

  @media(max-width: 1200px) {
    .header {
      &__top {
        &__logo {
          width: auto;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .header {
      box-shadow: var(--box-shadow-primary);

      &__top {
        &__container {
          display: flex;
          gap: 1.5rem;
          height: 50px;
          padding: 0.625rem;
        }

        &__logo {
          height: 100%;
          display: inline-block;

          &__img {
            width: 100px;
            height: 30px;
          }
        }

        &__logo-wrapper {
          height: 30px;
          margin-right: auto;
        }

        &__search {
          width: auto;
        }
      }
    }
  }

  .header__bottom {
    display: grid;
    width: 100%;
    min-width: 0;
    grid-template-rows: 0fr;
    transition: grid-template-rows ease-in-out 100ms;
    border-top: 1px solid var(--color-medium-gray);

    @media(max-width: 992px) {
      border-top: none;
    }

    &--open {
      grid-template-rows: 1fr;

      .header__bottom__inner {
        animation: show-overflow ease-in-out 100ms;
        overflow: visible;
      }
    }

    &__inner {
      position: relative;
      display: flex;
      width: 98%;
      margin: 0 auto;
      min-width: 0;
      overflow: hidden;

      &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
        min-width: 0;

        &__content {
          display: flex;
          align-items: center;
          flex: 0 0 auto;
        }

        @media(max-width: 992px) {
          align-items: center;
          gap: 20px;
          padding: 10px 15px;
        }
      }
    }
  }

  .navigation {
    position: relative;
    width: 100%;
    padding-left: 2rem;

    ul {
      display: flex;
      gap: 1rem;
      padding: 0;
      margin: 0;
      list-style: none;

      li.nav-item {
        position: relative;

        :deep(.nav-item-arrow) {
          transform: rotate(0);
          font-size: 0.75rem;
          margin-left: 0.5rem;
        }

        :deep(> .children-wrapper) {
          top: 100%;
          left: 0;
          right: unset;
          height: auto;
          padding: 0.5rem;
          min-width: 300px;
          border-radius: 0;

          a {
            font-weight: var(--font-weight-500);
            padding: 0.5rem 0;
          }

          .nav-item.level-2 .children-wrapper {
            display: flex;
            flex-direction: column;
          }

          .children {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            padding-left: 1rem;
          }

          @media(max-width: 1200px) {
            left: unset;
            right: 0;
          }
        }
      }
    }

    :deep(.level-0) {
      & > a {
        text-decoration: none;
        padding: 1rem 0.375rem;
        color: var(--color-black);
        font-weight: var(--font-weight-400);
        transition-duration: var(--transition-primary-duration);
        transition-timing-function: var(--transition-primary-timing-function);
        z-index: 2;

        &:not(.active):hover {
          color: var(--primary-color);
        }

        &.active {
          position: relative;
          display: inline-block;
          font-weight: var(--font-weight-700);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .navigation {
      padding-left: 10px;

      ul {
        gap: 14px;
        font-size: 15px;
      }
    }
  }
</style>
