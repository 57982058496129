<script setup>
  import { useBreakpoints } from '$storefront';

  const breakpoints = useBreakpoints();
  defineProps({
    content: { type: String, required: false },
    name: { type: String, required: false },
    link: { type: String, required: false },
    photos: { type: Array, required: false },
  });
</script>

<template>
  <div class="about-wrapper">
    <div class="about-header">
      <MediaPicture class="about-logo" :photo="photos" type="icon" />
      <SfButton small transparent class="btn-custom" :href="link" v-if="!breakpoints.xl" :aria-hidden="breakpoints.xl" :class="{'hide': breakpoints.xl}">
        <template #default>
          {{ $t('Zobacz więcej') }}
        </template>
        <template #after>
          <SfIconChevronRight />
        </template>
      </SfButton>
    </div>
    <p class="info-text" v-html="content" />
    <div class="button-wrapper" v-if="breakpoints.xl">
      <SfButton :href="link">{{ name }}</SfButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 3rem;
    padding: 3rem 4.5rem;
    background-color: var(--color-white);
    border-radius: 0.375rem;
    flex-grow: 1;
    line-height: 1.5;

    .button-wrapper {
      text-align: end;
    }

    .btn-custom.hide {
      display: none;
    }

    .about-header {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;

      :deep(.about-logo) {
        max-width: 50%;

        img {
          height: 70px;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .about-wrapper {
      padding: 2rem;
    }
  }

  @media(max-width: 992px) {
    .about-wrapper {
      padding: 1rem;
      gap: 1rem;
    }
  }
</style>
