<script setup>
  import { ref } from 'vue';
  import { useSession, route } from '@plenny/visitor';

  const session = useSession();

  const show = ref(false);
</script>
<template>
  <nav class="navigation">
    <ul class="menu">
      <li class="item">
        <VisitorLink explicit :href="route('web.homepage.index')">
          <SfIconHouse />
          {{ $t('Start') }}
        </VisitorLink>
      </li>
      <li class="item">
        <button class="categories-button" @click="show = true">
          <SfIconPanel />
          <span>{{ $t('Kategorie') }}</span>
        </button>
      </li>
      <li class="item">
        <VisitorLink :href="route('web.product.index')">
          <SfIconBag />
          {{ $t('Sklep') }}
        </VisitorLink>
      </li>
      <li class="item">
        <VisitorLink explicit :href="session.is_authenticated ? route('web.account.show') : route('web.account.session.create')">
          <SfIconPerson />
          {{ $t('Konto') }}
        </VisitorLink>
      </li>
      <li class="item">
        <!-- when user is not logged in those two routes redirect to the same route, so two menu item gets .active class - in order to reset .active styles when needed we can add an extra unauthenticated-whisthist class -->
        <VisitorLink explicit :href="session.is_authenticated ? route('web.account.wishlist.index') : route('web.account.session.create')" :class="{'unauthenticated-whisthist': !session.is_authenticated }">
          <SfIconHeart class="icon-heart" />
          {{ $t('Schowek') }}
        </VisitorLink>
      </li>
    </ul>
  </nav>
  <SfDrawerCategories v-model:open="show" />
</template>

<style scoped lang="scss">
  .navigation {
    background-color: var(--color-white);
    height: 58px;
    box-shadow: 0 3px 10px #000029;

    .menu {
      display: flex;
      justify-content: space-evenly;
      height: 100%;
      list-style: none;
      font-size: 0.75rem;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        font-size: 0.75rem;
        gap: 0.125rem;

        .categories-button {
          background-color: transparent;
          border: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 0.125rem;
          font-size: 0.75rem;
          color: var(--color-black)
        }


        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.125rem;
          transition: font-weight .1s ease-out;

          svg {
            fill: var(--color-white);
            transition: all 0.9s ease-out;

          }

          &.active {
            font-weight: bold;

            :deep(svg) {
              path {
                fill: var(--color-black);
              }
            }

            &.unauthenticated-whisthist {
              font-weight: normal;

              :deep(svg) {
                path {
                  fill: none;
                }
              }
            }
          }
        }

        svg {
          font-size: 23px;
        }
      }
    }
  }
</style>
