<script lang="ts" setup>

</script>

<template>
  <div class="promotion-notification">
    <IconBell class="promotion-notification__icon" />

    <div class="promotion-notification__content">
      <span>
        {{ $t('Do końca promocji zostało: ') }}
      </span>

      <span class="promotion-notification__date">
        {{ $t('2 DNI 04:15:45') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .promotion-notification {
    border: 2px solid var(--color-primary);
    padding: 0.75rem 1rem;
    margin: 0.5rem 0;
    display: flex;

    &__icon {
      color: var(--color-primary);
    }

    &__content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 0.75rem;
      font-weight: bold;
    }


    &__date {
      color: var(--color-primary);
    }
  }
</style>