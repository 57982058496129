<script lang="ts" setup>
  import { transChoice } from '@plenny/translator';
  import { computed } from 'vue';

  const props = defineProps({
    realization_from: { type: Number, required: false },
    realization_to: { type: Number, required: false },
  });

  const realization = computed(() => {
    let min = props.realization_from;
    let max = props.realization_to;

    if (min && max) {
      return transChoice(':min-:max dzień roboczy|:min-:max dni robocze|:min-:max dni roboczych', max, { min, max });
    }

    if (max) {
      return transChoice(':max dzień roboczy|:max dni robocze|:max dni roboczych', max, { max });
    }

    return null;
  });
</script>
<template>
  <div v-if="realization" class="realization-option-summary">
    <SfIconClock class="realization-option-summary__icon" />
    <div class="realization-option-summary__content">
      <span class="realization-option-summary__property">{{ $t('Szacowany termin realizacji') + ':' }}</span>
      <span class="realization-option-summary__value">{{ realization }}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .realization-option-summary {
    display: flex;
    gap: 0.75rem;
    padding: 0.5rem 0;
    font-size: 0.875rem;

    &__icon {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__value {
      font-weight: var(--sf-font-weight-600);
    }
  }
</style>
