<script setup>
  import { watch, computed, ref } from 'vue';
  import { useScrollWidth, useBreakpoints, useFixedActionsSpacer, useScrollDetector } from '$storefront';
  import { useShared, $delete, route } from '@plenny/visitor';

  const { spacer, offset } = useFixedActionsSpacer();
  const scroll = useScrollWidth();
  const breakpoints = useBreakpoints();
  const shared = useShared();

  const isScrollingDown = ref(true);
  const isScrolled = ref(false);
  const scrollWidth = computed(() => scroll.value + 'px');
  const isComparator = computed(() => Object.keys(shared.value.comparator).length > 0);

  const backToTop = () => {
    document.querySelector('.bispol').scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  function actionChat() {
    window.Tawk_API?.toggle();
  }

  function clearComparator() {
    $delete(route('api.v1.web.comparator.destroy'));
  }

  useScrollDetector({
    threshold: 8,
    callback({ down, scrolled }) {
      isScrollingDown.value = down;
      isScrolled.value = scrolled;
    },
  });

  watch(isScrollingDown, (value) => {
    if (value) {
      spacer.value -= 58;
    } else {
      spacer.value += 58;
    }
  });

</script>
<template>
  <SfButton :class="{'compare-bar': isComparator }" class="chat fixed-actions-wrapper" rounded square tabindex="0" transparent @click="actionChat">
    <SfIconChat />
  </SfButton>
  <SfButton v-if="isScrolled" :class="{'compare-bar': isComparator }" class="back-to-top fixed-actions-wrapper" rounded square transparent @click="backToTop">
    <SfIconChevronUp />
  </SfButton>
  <div v-if="isComparator" class="comparator-bar fixed-actions-wrapper">
    <div class="comparator-bar__container container">
      <div class="comparator-bar__container-inner">
        <div class="comparator-bar__images">
          <MediaPicture v-for="item in shared.comparator" :photo="item.photo" sizes="100x100 2x, 50x50,80x80 2x max 992, 40x40 max 992" />
        </div>
      </div>
      <SfButton :href="route('web.comparator.index')" class="comparator-bar__compare-btn" primary small>
        {{ $t('Porównaj') }}
        <template #after>
          {{ Object.keys(shared.comparator).length }}
        </template>
      </SfButton>
      <SfButton danger rounded square transparent @click="clearComparator">
        <SfIconTrash />
      </SfButton>
    </div>
  </div>
  <div v-if="!breakpoints.lg" :class="{ 'is-scrolling-down': isScrollingDown }" class="bottom-nav-wrapper">
    <BottomNavigation />
  </div>
</template>
<style lang="scss" scoped>
  .comparator-bar,
  .back-to-top,
  .chat {
    --bottomOffset: v-bind(offset);
    position: fixed;
    bottom: 0;
    z-index: 9;
  }

  .back-to-top,
  .chat {
    cursor: pointer;
    font-size: 1.5rem;
    width: 90px;
    height: 90px;
    color: var(--color-white);

    &.compare-bar {
      bottom: 60px;
    }

    svg {
      position: absolute;
      bottom: 12px;
    }
  }

  .back-to-top {
    --space: v-bind(scrollWidth);
    right: var(--space);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
    background-color: var(--color-primary);

    svg {
      right: 15px;
    }
  }

  .chat {
    left: 0;
    clip-path: polygon(0 0, 100% 100%, 0 100%);
    background-color: var(--color-secondary);

    svg {
      left: 15px;
    }
  }

  .comparator-bar {
    --space: v-bind(scrollWidth);
    right: var(--space);
    left: 0;
    height: 60px;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    box-shadow: var(--box-shadow-primary);
    z-index: 4;

    &__container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }

    &__images {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 1rem;

      :deep(picture) {
        flex-grow: 0;
        flex-shrink: 0;

        img {
          aspect-ratio: 1;
        }
      }
    }

    &__compare-btn :deep(.btn__after) {
      background-color: var(--color-primary);
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0.75rem;
      line-height: 1;

      padding: 0.25rem 0.375rem;
      border-radius: 1.5rem;
      color: var(--color-white);
      transform: translate(-50%, -50%);
    }
  }

  .bottom-nav-wrapper {
    width: 100%;
    position: fixed;
    z-index: 5;
    bottom: 0;
    left: 0;
    height: 58px;
    transform: translateY(0);
    transition: transform 0.2s;
    background-color: var(--color-white);

  }

  .bottom-nav-wrapper.is-scrolling-down {
    transform: translateY(58px);
  }

  @media (max-width: 992px) {
    .fixed-actions-wrapper {
      transform: translateY(calc(-1 * var(--bottomOffset)));
      transition: transform 0.2s;
    }

    .comparator-bar {
      &__container {
        padding: 0 0.625rem 0 0;
        gap: 0.625rem;
      }

      &__images {
        gap: 0.5rem;
        flex: 0 0 auto;

        :deep(img) {
          aspect-ratio: 1;
        }
      }

      &__container {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 2rem;
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          z-index: 5;
          pointer-events: none;
        }
      }


      &__container-inner {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }


    .back-to-top,
    .chat {
      cursor: pointer;
      font-size: 1rem;
      width: 60px;
      height: 60px;

      svg {
        bottom: 8px;
      }
    }

    .back-to-top svg {
      right: 8px;
    }

    .chat svg {
      left: 8px;
    }
  }
</style>
