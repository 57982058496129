<script lang="ts" setup>
  import { useShared } from '@plenny/visitor';

  const shared = useShared();
</script>
<template>
  <footer class="footer">
    <div class="footer__newsletter">
      <Newsletter class="container" />
    </div>
    <div class="footer__top container">
      <div class="footer__block footer__block--contact">
        <SfLogo class="footer__logo" />
        <div v-if="shared.department" class="footer__contact-wrapper">
          <div class="footer__contact-item">
            <SfIconGeoAlt />
            <div class="footer__contact-department">
              <span class="footer__contact-bold">BISPOL SP. Z O.O</span>
              <a :href="shared.department.maps || '#'" class="footer__contact-content" target="_blank">
                <span v-if="shared.department.first_line" v-text="shared.department.first_line" />
                <span v-if="shared.department.second_line" v-text="shared.department.second_line" />
                <span v-if="shared.department.third_line" v-text="shared.department.third_line" />
              </a>
            </div>
          </div>

          <div class="footer__contact-item">
            <SfIconPhoneOutbound />
            <div class="footer__contact-grid">
              <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link">
                <span v-html="shared.department.phone" />
                {{ $t('Sekretariat') }}
              </a>
              <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link">
                <span v-html="shared.department.phone" />
                {{ $t('Sprzedaż detal') }}
              </a>
              <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link">
                <span v-html="shared.department.phone" />
                {{ $t('Spzredaż hurt') }}
              </a>
              <a v-if="shared.department.phone" :href="`tel:${shared.department.phone}`" class="footer__phone-link">
                <span v-html="shared.department.phone" />
                {{ $t('Fax') }}
              </a>
            </div>
          </div>

          <div class="footer__contact-item footer__contact-item--flex-center">
            <SfIconEnvelope />
            <a :href="shared.department.email" class="footer__contact-bold" v-html="shared.department.email" />
          </div>
        </div>
      </div>
      <div class="footer__blocks">
        <div v-for="group in shared.menu.menu_bottom" class="footer__block">
          <span class="footer__block-title">
            {{ group.name }}
          </span>
          <ul class="footer__list">
            <li v-for="item in group.children" class="footer__list-item">
              <VisitorLink
                :explicit="item.explicit"
                :href="item.url"
                :rel="item.rel"
                :target="item.target"
                class="link">
                {{ item.name }}
              </VisitorLink>
            </li>
          </ul>
        </div>
        <div class="footer__social-wrapper">
          <a class="footer__social" href="https://www.facebook.com/bispolcandles/?locale=pl_PL" target="_blank">
            <SfIconFacebook />
          </a>
          <a class="footer__social" href="https://www.instagram.com/bispol_candles/" target="_blank">
            <SfIconInstagram />
          </a>
        </div>
      </div>
    </div>
    <div class="container footer__bottom">
      <Separator />
      <div class="footer__copyright">
        <CopyrightMessage />
        <p>
          {{ $t('Realizacja') + ':' }} <a href="https://promoznawcy.pl/" target="_blank">promoznawcy.pl</a>
        </p>
      </div>
    </div>
    <FixedActions />
  </footer>
</template>
<style lang="scss" scoped>
  .footer {
    margin-top: 65px;
    color: var(--color-white);
    background-color: var(--bispol-dark);
    font-size: 0.75rem;
    text-transform: uppercase;
    background-image: url("../../../assets/images/footer-decoration.png");
    background-repeat: no-repeat;
    background-position: 30% 100%;

    a {
      color: var(--color-white);

      &:hover {
        color: var(--color-primary);
      }
    }

    &__newsletter {
      background: var(--color-primary-body-background);
    }

    &__logo {
      height: 60px;
      max-width: 175px;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 4rem;
      padding-top: 65px;
    }

    &__contact-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      svg {
        font-size: 1.25rem;
      }
    }

    &__contact-item {
      display: flex;
      gap: 1.5rem;
      margin-top: 0.5rem;

      svg {
        color: var(--color-primary);
        font-size: 1.5rem;
      }

      &--flex-center {
        align-items: center;
        text-transform: none;
        text-decoration: underline;
      }
    }

    &__contact-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 5rem;
      row-gap: 1.5rem;
    }

    &__phone-link {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      span {
        font-size: 0.875rem;
        font-weight: bold;
      }
    }

    &__contact-department {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__contact-bold {
      font-weight: bold;
      font-size: 0.875rem;
    }

    &__contact-content {
      display: flex;
      flex-direction: column;
    }

    &__address {
      display: flex;
      flex-direction: column;
    }

    &__blocks {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 6rem;
    }

    &__block {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 2.5rem;
      padding: 0 2.5rem;


      &:first-child {
        padding-left: 0;
      }
    }

    &__social-wrapper {
      display: flex;
      gap: 1rem;
    }

    &__social {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-dark-grey);

      svg {
        font-size: 1.125rem;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      list-style: none;
    }

    &__block-title {
      font-size: 1.5rem;
      font-weight: var(--font-weight-700);
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      font-size: 0.75rem;
      padding: 3rem 0;
    }

    &__copyright {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (max-width: 1200px) {
    .footer {
      &__top {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
      }

      &__block {
        &:not(:last-child) {
          border-right: none;
        }

        &:first-child {
          padding-left: 2.5rem;
        }
      }

      &__bottom {
        flex-direction: column;
        align-items: center;
        font-size: 0.75rem;
        gap: 0.625rem;
      }
    }
  }

  @media (max-width: 992px) {
    .footer {
      padding-top: 2.5rem;
      margin-top: 1.5rem;

      &__logo {
        height: 40px;
        max-width: 125px;
      }

      &__top {
        grid-template-columns: 1fr;
        gap: 2.5rem;
        padding: 0 0.625rem 60px;
      }

      &__block {
        padding: 0;
        gap: 1.25rem;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
</style>
