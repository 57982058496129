<script setup>
  import { ref } from 'vue';

  const visible = ref(true);

  function onClose() {
    visible.value = false;
  }
</script>
<template>
  <transition name="fade">
    <div class="top-bar" v-if="visible">
      <div class="top-bar__content container">
        <div class="top-bar__item">
          <span class="top-bar__icon">
            <SfIconTags />
          </span>
          <span class="top-bar__text top-bar__name">
            {{ $t('Niepowtarzalne zapachy modelu Aurelia - 10%') }}
          </span>
        </div>
        <div class="top-bar__item">
          <span class="top-bar__text">
            {{ $t('Promocja trwa do końca: 02/10/2022') }}
          </span>
          <SfButton primary>
            {{ $t('Sprawdź') }}
          </SfButton>
          <SfButton class="top-bar__close" transparent @click="onClose">
            <template #before>
              <SfIconClose />
            </template>
          </SfButton>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
  .top-bar {
    position: sticky;
    display: flex;
    background: var(--bispol-primary);
    width: 100%;
    z-index: 999;

    --sf-btn-font-size: 0.75rem;
    --sf-btn-padding-y: 0.125rem;
    --sf-btn-padding-x: 1rem;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding: 0.5rem 0;
    }

    &__icon {
      display: flex;
      color: var(--color-white);
      font-size: 1.25rem;
    }

    &__item {
      display: flex;
      gap: 1rem;
      line-height: 1.25rem;
      align-items: center;
    }

    &__text {
      color: var(--color-black);
      font-weight: var(--font-weight-700);
    }

    &__name {
      color: var(--color-white);
    }

    &__close {
      color: var(--color-white);
      padding: 0;

      svg {
        font-size: 1.25rem;
      }
    }
  }

  @media(max-width: 992px) {
    .top-bar {
      &__content {
        padding: 0.625rem;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
</style>
