<template>
  <div class="order-benefits-info">
    <SfIconArrowCounterclockwise />
    {{ $t('Bezpłatny zwrot do 14 dni') }}
  </div>
</template>

<style scoped lang="scss">
  .order-benefits-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--color-gray);

    svg {
      font-size: 1.25rem;
    }
  }

  @media(max-width: 1200px) {
    .order-benefits-info {
      font-size: 0.875rem;
      color: var(--color-gray);
      background-color: var(--color-white);
    }
  }
</style>
