<script setup>
  import { $patch, route } from '@plenny/visitor';
  import { ref, computed } from 'vue';
  import { trans } from '@plenny/translator';

  const signUpEmail = ref(false);

  function onSubmit(data) {
    return $patch(route('api.v1.web.newsletter.update'), data).then(() => {
      signUpEmail.value = true;
    });
  }

  const privacyPolicy = computed(() => {
    return trans('Akceptuję <a href=":url" target="_blank">politykę prywatności</a> sklepu.', { url: route('web.privacy_policy.index') });
  });
</script>
<template>
  <div class="newsletter">
    <div class="newsletter__header">
      <SegmentHeading larger type="h2">
        {{ $t('Newsletter') }}
      </SegmentHeading>
      <p class="newsletter__text">
        {{ $t('Bogata oferta produktów, która w 100% spełnia oczekiwania naszych klientów') }}
      </p>
    </div>
    <template v-if="!signUpEmail">
      <VisitorForm v-slot="{ submitting }" @submit="onSubmit">
        <div class="newsletter__content">
          <div class="newsletter__input">
            <SfFormInput :label="$t('E-mail')" name="email" required />
            <SfButton :loading="submitting" type="submit">
              {{ $t('Zapisz się') }}
            </SfButton>
          </div>
          <div class="newsletter__consent">
            <SfFormSwitch name="acceptance_rodo" required>
              <span v-html="privacyPolicy" />
            </SfFormSwitch>
          </div>
        </div>
      </VisitorForm>
    </template>
    <template v-else>
      <p class="subscribed">{{ $t('Dziękujemy za zapisanie się do naszego newslettera') }}</p>
    </template>
  </div>
</template>
<style lang="scss">
  .newsletter {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 60px 40px;
    background-color: var(--color-primary-body-background);
    color: var(--color-black);

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__content {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
    }

    &__input {
      display: flex;
    }

    &__consent {
      --sf-checkbox-label-font-size: 0.75rem;
      --sf-checkbox-label-color: var(--color-gray);

      a {
        text-decoration: underline;
      }
    }

    .subscribed {
      font-weight: 600;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @media (max-width: 1200px) {
    .newsletter {
      &__content {
        flex-direction: column;
        gap: 0.625rem;
      }
    }
  }

  @media (max-width: 992px) {
    .newsletter {
      padding: 0.625rem;

      &__content {
        margin-top: 0;
        align-items: flex-end;
      }
    }
  }
</style>

