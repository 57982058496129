<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    product: Object,
    sections: Array,
    brand: Object,
    category: Object,
    page: Object,
    recommended: Array,
  });

  const photosParameters = ref({
    slidesPerView: 1,
    navigation: true,
    pagination: false,
    loop: false,
    watchSlidesProgress: true,
  });

  const thumbsParameters = ref({
    slidesPerView: 'auto',
    navigation: true,
    pagination: false,
    loop: false,
    centeredSlidesBounds: true,
    spaceBetween: 10,
  });

  const variant = defineModel('variant', { type: Object, required: false });

</script>
<template>
  <div class="container product-details">
    <div class="product-details__gallery-wrapper">
      <SfProductGallery v-bind="{ thumbsParameters, photosParameters, photos: [variant?.photo, ...product.photos]}" />
    </div>
    <div class="product-details__content">
      <div class="product-details__info">
        <div v-if="product.is_new || (product.price_gross !== product.regular_gross)" class="product-details__labels">
          <SfProductTag :visible="product.is_new" tagType="novelty">
            <template #content>{{ $t('Nowość') }}</template>
          </SfProductTag>
          <SfProductTag :visible="product.price_gross !== product.regular_gross" tagType="discount">
            <template #content>{{ $t('Promocja') }}</template>
          </SfProductTag>
        </div>
        <header class="product-details__header">
          <h1 class="product-details__title" v-html="variant?.title || product.title" />
          <SfStars :count="product.reviews_count" :rating="product.reviews_average" class="product-details__stars" />
          <PromotionNotification />
          <div v-if="variant?.description_short || product.description_short" class="product-details__description"
            v-html="variant?.description_short || product.description_short" />
        </header>
      </div>
      <div class="product-details__actions">
        <ProductMainActions v-model:variant="variant" :brand :product="product" />
      </div>
      <ProductMetaData :brand :category :product :variant />
      <a class="product-details__to-specification" href="#specification">
        {{ $t('Przejdź do pełnej specyfikacji') }}
      </a>
      <WishlistAndCompareButtons :variant="variant" />
      <MoreVariants :recommended="recommended" />
    </div>
  </div>
</template>
<style lang="scss">
  .product-details__gallery-wrapper .items-carousel {
    --sf-carousel-button-border-radius: 0;
    --sf-carousel-button-background: var(--color-black);
    --sf-carousel-button-color: var(--color-white);
    --sf-carousel-button-color-hover: var(--color-white);
    --sf-carousel-button-border-width: 0;
    --sf-carousel-button-background-hover: var(--color-black-hover-active);
    --sf-carousel-button-icon-size: 1rem;

    --sf-product-gallery-gap: 1.25rem;
    --sf-product-gallery-thumbs-height: 70px;
    --sf-product-gallery-thumbs-width: 70px;
    --sf-product-gallery-thumbs-border-radius: 0;
    --sf-product-gallery-thumbs-border: var(--color-line-primary);
    --sf-product-gallery-thumbs-active-border: var(--color-primary);
    --sf-product-gallery-thumbs-padding-x: 0.25rem;
    --sf-product-gallery-thumbs-padding-y: 0.25rem;
    --sf-carousel-button-space: 0;
  }
</style>

<style lang="scss" scoped>
  .product-details {
    display: grid;
    grid-template-columns: 40% 1fr;
    gap: 3rem;
    padding: 1rem;
    width: 100%;
    background-color: var(--color-white);

    &__gallery-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    &__gallery-wrapper {
      width: 100%;
      gap: 1.5rem;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    &__labels {
      display: flex;
      gap: 1rem;
      margin-bottom: 0.375rem;
    }

    &__title {
      font-size: 1.5rem;
      line-height: 1.5;
      font-weight: var(--font-weight-700);
    }

    &__description {
      font-size: 0.875rem;
    }

    &__info {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }

    &__to-specification {
      margin-top: 0.5rem;
      text-decoration: underline;
      transition-property: color;
      transition-duration: var(--transition-primary-duration);
      transition-timing-function: var(--transition-primary-timing-function);
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
      }
    }
  }

  @media (max-width: 1200px) {
    .product-details {

      gap: 2rem;
      padding: 2rem 1rem;
      width: 98%;

      &__info {
        padding-right: 0;
        border-right: 0;
      }

      &__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
      }

      &__actions {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 992px) {
    .product-details-container {
      margin-bottom: 1.5rem;

      .product-details {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0.625rem;

        &__title {
          font-size: 1.375rem;
        }

        &__actions {
          --sf-product-price-presentation-catalog-font-size: 0.875rem;
          --sf-product-price-presentation-current-font-size: 2rem;
          --sf-product-price-presentation-gap: 0;

          :deep(.product-actions) {
            gap: 2rem;

            .product-actions__info {
              gap: 1.25rem;
            }

            .price--presentation {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }
      }
    }
  }
</style>
