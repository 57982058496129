<script lang="ts" setup>
  import { computed } from 'vue';
  import { $delete, route, $post, useShared } from '@plenny/visitor';

  const props = defineProps({
    variant: { type: Object, required: true },
  });

  const shared = useShared();

  const isCompared = computed(() => {
    if (props.variant && shared.value.comparator) {
      return shared.value.comparator.map((c) => c.variant_id).includes(props.variant.id);
    } else {
      return false;
    }
  });

  function toggleComparator() {
    if (isCompared.value) {
      $delete(route('api.v1.web.comparator.destroy', { 'variant_id': props.variant.id }));
    } else {
      $post(route('api.v1.web.comparator.store', { 'variant_id': props.variant.id }));
    }
  }
</script>

<template>
  <div class="compare-wishlist">
    <SfWishlistButton v-slot="{ saved }" :variant="variant.id">
      <SfButton :class="{ saved }" class="favourite" small transparent>
        <template #before>
          <IconHeartFill v-if="saved" class="compare-wishlist__icon-fill" />
          <IconHeart v-else />
        </template>
        <template #default>
          <span v-if="saved">{{ $t('Ulubiony') }}</span>
          <span v-else>{{ $t('Dodaj do ulubionych') }}</span>
        </template>
      </SfButton>
    </SfWishlistButton>
    <SfButton v-if="variant" small transparent @click="toggleComparator">
      <template #before>
        <IconArrowDownUp />
      </template>
      <template #default>
        {{ isCompared ? $t('W porównaniu') : $t('Porównaj') }}
      </template>
    </SfButton>
  </div>
</template>

<style lang="scss" scoped>
  .compare-wishlist {
    padding: 0.5rem 0;
    margin: 0.25rem 0;

    &__icon-fill{
      color: var(--color-red);
    }

    @media (max-width: 992px) {
      padding: 1rem 0;
    }
  }
</style>